import React from "react"
import { RightOutlined } from "@ant-design/icons"
import Slider from "react-slick"

import Layout from "../../components/Layout/LayoutPublic/LayoutPublic"
import { SectionInterest } from "../../components/MayInterestYou/MayInterestYou"

import card from "../../assets/images/cart.png"
import card1 from "../../assets/images/card1.png"
import card2 from "../../assets/images/card2.png"
import card3 from "../../assets/images/card3.png"

const Resources = () => {
  const dummyCardsBanner = [
    {
      id: 1,
      title: "Brandbook",
      background: card1,
      text:
        "Enim, id nunc nec, interdum facilisi dui lacus. Id in sed amet id convallis dictumst commodo sed. Tristique tellus porttitor suspendisse ac.",
    },
    {
      id: 2,
      title: "Changelog",
      background: card2,
      text:
        "Sed netus at tincidunt potenti sit sed pretium. Purus integer dui diam morbi enim, quis mi. Tortor quam turpis quam at quis. Ornare urna.",
    },
    {
      id: 3,
      title: "Webinars",
      background: card3,
      text:
        "Suspendisse porttitor non nisi vivamus enim in consectetur et. In nascetur ac convallis donec quam duis. Egestas facilisis tortor nunc nunc.",
    },
  ]

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  return (
    <Layout section="section_ligth">
      <div className="means">
        <div className="means__container">
          <h1 className="title">Recursos</h1>
          <div className="cards">
            {dummyCardsBanner?.map((item, index) => (
              <div
                key={index}
                className="cards__card"
                data-aos="fade-up"
                style={{ backgroundImage: `url(${item.background})` }}
              >
                <div className="head">
                  <h2>{item.title}</h2>
                  <RightOutlined />
                </div>
                <p>{item.text}</p>
              </div>
            ))}
          </div>
          <div className="caroucel-cards">
            <Slider {...settings}>
              {dummyCardsBanner?.map((item, index) => (
                <div key={index} className="cards__card" data-aos="fade-up">
                  <div
                    className="cards__card"
                    style={{ backgroundImage: `url(${item.background})` }}
                  >
                    <div className="head">
                      <h2>{item.title}</h2>
                      <RightOutlined />
                    </div>
                    <p>{item.text}</p>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
        <SectionInterest />
      </div>
    </Layout>
  )
}

export default Resources
